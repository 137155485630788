import React from 'react'
import Inview from 'components/Inview'
import * as styles from './styles.module.scss'

const Block = ({ content }) => {
  const { tableHtml, productCatSlug } = content
  const noBorderInnerClass =
    productCatSlug === 'semiconductor' ? styles.noBorderInner : ''
  return (
    <div className='block pad-v-standard'>
      <Inview className='container fade-in up grid-24'>
        <div className={`${styles.tableWrap} ${noBorderInnerClass}`}>
          <div dangerouslySetInnerHTML={{ __html: tableHtml }} />
        </div>
      </Inview>
    </div>
  )
}

export default Block

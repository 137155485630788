import React from 'react'
import BannerImageText from 'blocks/BannerImageText/template'
import TextBody from 'blocks/TextBody/template'
import ProductsGrid from 'blocks/_dynamic/ProductsGrid/template'
import TableOnlyHtml from 'blocks/TableOnlyHtml/template'
import HeaderVersionWrap from 'components/HeaderVersionWrap'
import Footer from 'components/Footer'

const ContentType = ({ pageId, content, locale, layoutProps }) => {
  if (!locale) locale = 'en'

  const strings = {
    en: {
      textBodyTitle: 'Product Introduction',
      productsTitle: 'Product Line',
    },
    zh: {
      textBodyTitle: '产品介绍',
      productsTitle: '产品线',
    },
  }

  const pageContent = {
    bannerImageText: {
      pretitle: content.thisPage.title,
      title: content.title,
      imageSet: content.imageSet,
    },
    textBody: {
      title: strings[locale].textBodyTitle,
      body: content.intro,
      body2: content.body,
    },
    productsGrid: {
      title: strings[locale].productsTitle,
      productCatSlug: content.slug,
    },
    tableOnlyHtml: {
      tableHtml: content.tableHtml,
      productCatSlug: content.slug,
    },
  }

  return (
    <div className='page'>
      <div className='block theme bg-dark'>
        <HeaderVersionWrap layoutProps={layoutProps} isDark={true}>
          <BannerImageText
            content={pageContent.bannerImageText}
            layoutProps={layoutProps}
            theme='dark'
            isImageDarker={true}
          />
        </HeaderVersionWrap>
      </div>
      <div className='block theme bg-light'>
        <HeaderVersionWrap layoutProps={layoutProps} isDark={false}>
          <TextBody content={pageContent.textBody} />
          <ProductsGrid content={pageContent.productsGrid} locale={locale} />
        </HeaderVersionWrap>
      </div>
      <div className='block theme bg-shade'>
        <HeaderVersionWrap layoutProps={layoutProps} isDark={false}>
          {content.tableHtml && (
            <TableOnlyHtml content={pageContent.tableOnlyHtml} />
          )}
        </HeaderVersionWrap>
      </div>
      <Footer locale={locale} />
    </div>
  )
}

export default ContentType

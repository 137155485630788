import React from 'react'
import { graphql } from 'gatsby'
import Seo from 'components/Seo'
import PageTemplate from './template'

const ContentType = (props) => {
  const { data, pageContext, layoutProps } = props
  const { locale } = pageContext
  const { page, productPage } = data
  let content = page.frontmatter[locale]
  content.thisPage = productPage.frontmatter[locale]
  content.seo = {
    title: `${content.thisPage.title} - ${content.title}`,
  }

  return (
    <>
      <Seo content={content} locale={locale} />
      <PageTemplate
        pageId={page.id}
        content={content}
        locale={locale}
        layoutProps={layoutProps}
      />
    </>
  )
}

export default ContentType

export const pageQuery = graphql`
  query ($id: String!) {
    productPage: markdownRemark(
      frontmatter: { en: { slug: { eq: "products" } } }
    ) {
      id
      frontmatter {
        en {
          title
          slug
        }
        zh {
          title
          slug
        }
      }
    }
    page: markdownRemark(id: { eq: $id }) {
      id
      frontmatter {
        en {
          slug
          title
          intro
          body
          tableHtml
          image {
            childImageSharp {
              gatsbyImageData
            }
          }
          imageSet {
            image {
              childImageSharp {
                gatsbyImageData
              }
            }
            imageMobile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          seo {
            title
            description
          }
        }
        zh {
          slug
          title
          intro
          body
          tableHtml
          image {
            childImageSharp {
              gatsbyImageData
            }
          }
          imageSet {
            image {
              childImageSharp {
                gatsbyImageData
              }
            }
            imageMobile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          seo {
            title
            description
          }
        }
      }
    }
  }
`
